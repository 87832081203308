import axios from 'axios'

export class Presenca {
  //axios.get('/professor/horario?servidor_id='+servidor_id+'&ano='+ano)
  static async presencasLancadasPorPeriodo (turma_id, disciplina_id) {
    return axios.get('presencas-lancadas-por-periodo/turma/'+turma_id+'/disciplina/'+disciplina_id);
  }
  static async exibirCalendarioPresenca (payload) {
    return axios.get('calendario/presenca/exibir?escola_id='+payload.escola_id+'&turma_id='+payload.turma_id+'&professor_id='+payload.professor_id);
  }
  static async criarPresencaDoProfessorTurma (payload) {
    return axios.post('presenca/turma/professor', payload)
  }
  static async apagarPresencaDoAluno(payload) {
    return axios.delete('presenca/calendario/exibir/one/deletar/'+payload.id + '/' + payload.turma_id + '/' + payload.disciplina_id + '/' + payload.segmento_id);
  }


  static async buscarPresencaTurmaProfDisc (payload) {
    return axios.get('presenca/turma/'+payload.turma_id+'/disc/'+payload.disciplina_id)
  }

  static async buscarPresencasParaVizualizar (payload) {
    return axios.get('presenca/professor/'+payload.professor_id+'/turma/'+payload.turma_id+'/'+payload.disciplina_id)
  }

  static async criarPresencaDosAlunos (array, info) {
    try {
      return axios.post('presenca', {
        array: array,
        segmento_id: parseInt(info.segmento_id),
        escola_id: parseInt(info.escola_id),
        turma_id: parseInt(info.turma_id),
        serie_id: parseInt(info.serie_id),
        professor_id: info.professor_id,
        disciplina_id: info.disciplina_id,
        professor_id: info.professor_id,
        data: info.data,
        qdt_presenca: info.qdt_presenca,
      });
    } catch (error) {

      throw error;
    }

  }

  static async criarPresencaDoAluno (info) {
    try {
      return axios.post('presenca/one', {
        escola_id: parseInt(info.escola_id),
        turma_id: parseInt(info.turma_id),
        segmento_id: parseInt(info.segmento_id),
        serie_id: parseInt(info.serie_id),
        professor_id: parseInt(info.professor_id),
        disciplina_id: parseInt(info.disciplina_id),
        aluno_id: parseInt(info.aluno_id),
        data: info.data,
        presenca: info.presenca
      });
    } catch (error) {
      throw error;
    }

  }


  static async EditarPresencaDaTurma (array, professor_id, turma_disciplina_id, turma_id) {
    try {
      return axios.put('presenca/turma/mes/aluno', {
        array: array,
        professor_id:professor_id,
        turma_disciplina_id: turma_disciplina_id,
        turma_id: turma_id,
      });
    } catch (error) {
      throw error;
    }

  }

  static async buscarPresencaTurmaConfig (payload) {
    return axios.post('presenca/turma/listar', payload)
  }

  static async presencaMontarCalendarioProfessor (payload) {
    return axios.get('presenca/calendario/prof/'+payload.disciplina_id+'/'+payload.mes+'/'+payload.turma_id+'/'+payload.segmento_id+'/'+payload.serie+'/'+payload.ano,
    {
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    }
    );
  }

  static async verificarPresencaDoDia (payload) {
    return axios.get('presenca/turma/verif_presenca_hoje?turma_id='+payload.turma_id+'&dia='+payload.dia+'&mes='+payload.mes+'&ano='+payload.ano+'&turma_disciplina_id='+payload.turma_disciplina_id+'&professor_id='+payload.professor_id);
  }

  static async buscarTurmaPresencaSemId (payload) {
    return axios.get('presenca/turmapresenca/semId?escola_id='+payload.escola_id+'&turma_id='+payload.turma_id+'&professor_id='+payload.professor_id);
  }

  static async buscarTurmaPresencaSemId2 (payload) {
    return axios.get('presenca/turmapresenca/semId2?escola_id='+payload.escola_id+'&turma_id='+payload.turma_id+'&professor_id='+payload.professor_id+'&disciplina_id='+payload.disciplina_id);
  }

  static async buscarPresencaDoDiaParaAluno2 (payload) {
    return axios.get('presenca/turma/alunos/dia?turma_id='+payload.turma_id+'&turma_presenca_id='+payload.turma_presenca_id+'&dia='+payload.dia+'&mes='+payload.mes+'&ano='+payload.ano);
  }

  static async buscarPresencasDoAluno (payload) {
    return axios.post('presenca/doaluno', payload)
  }

  static async mesesVisu (payload) {
    return axios.get('presenca/calendario/exibir/'+payload.disciplina_id+'/'+payload.mes+'/'+payload.turma_id+'/'+payload.ano,{
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    })
  }

  static async editarPresencaOne (payload,id) {
    try {
      return axios.put('presenca/calendario/exibir/edit/'+id,payload)
    } catch (error) {
      throw error;
    }  
  }

  static async justificarAFalta (payload) {
    return axios.put('presenca/falta/justificar/'+payload.presenca_id, payload);
  }
  static async buscarPresenca(presenca_id) {
    return axios.get('presenca/aluno/buscar/'+presenca_id);
  }

  static async buscarHistorioDePresencasDeAluno(payload) {
    return axios.get('presenca-cumulativa/historio/aluno/'+payload.aluno_id+'/'+payload.disciplina_id+'/'+payload.turma_atual_id);
  }
  /*static async hoje_presenca (payload) {
    return axios.get('presenca/turma/verif_presenca_hoje?turma_id='+payload.turma_id+'&professor_id='+payload.professor_id+'&disciplina_id='
    ++'&data='+);
  }*/

}
